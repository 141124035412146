<template>
  <div class="pagination font-family-raleway-medium d-flex">
    <button :disabled="currentPage != 1" style="cursor: pointer">
      <img src="../assets/icon/lefticon.svg" @click="$emit('prePage')" />
    </button>

    <button
      v-for="page in totalPage"
      :key="page.id"
      :class="currentPage === page ? 'active' : 'deactive'"
      @click="$emit('changePage', page)"
    >
      {{ page }}
    </button>

    <button :disabled="currentPage != totalPage" style="cursor: pointer">
      <img src="../assets/icon/righticon.svg" @click="$emit('nextPage')" />
    </button>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  // eslint-disable-next-line vue/no-unused-components
  props: ["totalPage", "currentPage"],
};
</script>

<style scoped>
.pagination {
  display: inline-block;
}

img {
  padding-top: 5px;
}

.pagination button {
  color: black;
  float: left;
  margin: 0 4px;
  text-align: center;
  text-decoration: none;
  width: 48px;
  height: 48px;
  background-color: #f5f9fc;
  border-radius: 12px;
}

.pagination div {
  margin: 0 4px;
  text-align: center;
  text-decoration: none;
  width: 48px;
  height: 48px;
  background-color: #f5f9fc;
  border-radius: 12px;
  cursor: pointer !important;
}

.pagination button.active {
  background-color: #2981e9;
  color: white;
  border-radius: 12px;
}
</style>
